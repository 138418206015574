import { Badge, HStack, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import CheckCircleIcon from '@material-design-icons/svg/sharp/check_circle.svg?react'

interface Props {
  nDefectsActiveHigh: number
  nDefectsActiveMedium: number
  nDefectsActiveLow: number
  children: React.ReactNode
}

export function DefectsNoSeverity({ nDefectsActiveHigh, nDefectsActiveMedium, nDefectsActiveLow, children }: Props) {
  if ([nDefectsActiveHigh, nDefectsActiveMedium, nDefectsActiveLow].every((defect) => defect === 0)) {
    return (
      <Badge variant="subtle" colorScheme="success" borderRadius="lg" paddingY={0}>
        <HStack>
          <Icon as={CheckCircleIcon} boxSize={4} />
          <Text textTransform="initial" fontWeight="normal">
            No Defects
          </Text>
        </HStack>
      </Badge>
    )
  }

  return children
}
