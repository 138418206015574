import { useEffect } from 'react'

import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, Heading, Stack } from '@chakra-ui/react'

import { MayhemStats } from '../../components/MayhemStats'
import { getProjectRunsPageTitle } from '../../titles'

import { useInterval, useQuery } from '../../hooks'

import { ProjectRunsList } from '../project-runs/ProjectRunsList'

import { useGetRunStatsByProjectQuery } from '../../redux/api/projects'
import { formatNumber } from '../../util/numbers'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspace: string
  project: string
}

export function ProjectRunsPage({ workspace: owner, project: projectSlug }: Props) {
  const targetFilter = useQuery().get('target') || undefined

  const {
    isLoading: isRunsStatsLoading,
    data: { active = 0, count = 0 } = {},
    refetch
  } = useGetRunStatsByProjectQuery({ owner, projectSlug, target: targetFilter })

  useEffect(() => {
    document.title = getProjectRunsPageTitle(owner, projectSlug)
  }, [owner, projectSlug])

  useInterval({
    action: refetch,
    deps: [refetch]
  })

  const stats = [
    { label: `Active Run${active !== 1 ? 's' : ''}`, value: formatNumber(active, { shorten: false }) },
    { label: `Total Run${count !== 1 ? 's' : ''}`, value: formatNumber(count, { shorten: false }) }
  ]

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${owner}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Runs</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>Runs</Heading>
      <Stack spacing={8}>
        <Card>
          <CardBody p={4}>
            <MayhemStats padded stats={stats} isFetching={isRunsStatsLoading} />
          </CardBody>
        </Card>
        <ProjectRunsList owner={owner} projectSlug={projectSlug} />
      </Stack>
    </Page>
  )
}
