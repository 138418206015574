import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['defect', 'valgrind_error', 'internal', 'jira', 'mapi', 'issue_rule', 'testcase report'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDefectByTarget: build.query<GetDefectByTargetApiResponse, GetDefectByTargetApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}`,
          params: { branch_name: queryArg.branchName, no_branch: queryArg.noBranch }
        }),
        providesTags: ['defect']
      }),
      putTargetDefect: build.mutation<PutTargetDefectApiResponse, PutTargetDefectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}`,
          method: 'PUT',
          body: queryArg.targetDefectPut
        }),
        invalidatesTags: ['defect']
      }),
      getDefectNavigation: build.query<GetDefectNavigationApiResponse, GetDefectNavigationApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/navigation`,
          params: { run_number: queryArg.runNumber }
        }),
        providesTags: ['defect']
      }),
      getDefectStates: build.query<GetDefectStatesApiResponse, GetDefectStatesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/states`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['defect']
      }),
      getTestcaseReportsByDefect: build.query<GetTestcaseReportsByDefectApiResponse, GetTestcaseReportsByDefectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/testcase_report`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage, run_number: queryArg.runNumber }
        }),
        providesTags: ['defect']
      }),
      getValgrindErrorsByDefect: build.query<GetValgrindErrorsByDefectApiResponse, GetValgrindErrorsByDefectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/valgrind_error`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['valgrind_error']
      }),
      getProjectDefects: build.query<GetProjectDefectsApiResponse, GetProjectDefectsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/defect`,
          params: {
            branch_name: queryArg.branchName,
            defect_type: queryArg.defectType,
            last_seen_gte: queryArg.lastSeenGte,
            last_seen_lt: queryArg.lastSeenLt,
            no_branch: queryArg.noBranch,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            q: queryArg.q,
            run: queryArg.run,
            severity: queryArg.severity,
            sorted_by: queryArg.sortedBy,
            status: queryArg.status,
            target: queryArg.target
          }
        }),
        providesTags: ['defect']
      }),
      getDefectStatsByProject: build.query<GetDefectStatsByProjectApiResponse, GetDefectStatsByProjectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/defect-stat`,
          params: { run: queryArg.run, target: queryArg.target }
        }),
        providesTags: ['internal']
      }),
      getDefectJiraFields: build.query<GetDefectJiraFieldsApiResponse, GetDefectJiraFieldsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/defect/${queryArg.defectNumber}/jira-fields`,
          params: { jira_issue_type: queryArg.jiraIssueType, jira_project_key: queryArg.jiraProjectKey }
        }),
        providesTags: ['jira']
      }),
      getIssueRules: build.query<GetIssueRulesApiResponse, GetIssueRulesApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/issue_rule` }),
        providesTags: ['mapi', 'issue_rule']
      }),
      getIssueRule: build.query<GetIssueRuleApiResponse, GetIssueRuleApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/issue_rule/${queryArg.issueRuleId}` }),
        providesTags: ['mapi', 'issue_rule']
      }),
      getDefectTrend: build.query<GetDefectTrendApiResponse, GetDefectTrendApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/defect-trend`,
          params: {
            window_end_timestamp_seconds: queryArg.windowEndTimestampSeconds,
            window_start_timestamp_seconds: queryArg.windowStartTimestampSeconds,
            window_step_seconds: queryArg.windowStepSeconds,
            project: queryArg.project,
            target: queryArg.target
          }
        }),
        providesTags: ['internal']
      }),
      getRestApiIssues: build.query<GetRestApiIssuesApiResponse, GetRestApiIssuesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/rest/issue`,
          params: {
            defect_numbers: queryArg.defectNumbers,
            exclude_suppressed: queryArg.excludeSuppressed,
            offset: queryArg.offset,
            per_page: queryArg.perPage
          }
        }),
        providesTags: ['mapi', 'testcase report']
      }),
      getProjectRestApiIssues: build.query<GetProjectRestApiIssuesApiResponse, GetProjectRestApiIssuesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/api/rest/issue`,
          params: {
            defect_numbers: queryArg.defectNumbers,
            exclude_suppressed: queryArg.excludeSuppressed,
            offset: queryArg.offset,
            per_page: queryArg.perPage
          }
        }),
        providesTags: ['mapi', 'testcase report']
      }),
      getRestApiIssue: build.query<GetRestApiIssueApiResponse, GetRestApiIssueApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/api/rest/issue/${queryArg.defectNumber}`
        }),
        providesTags: ['mapi', 'testcase report']
      }),
      getTestcaseResultsByRun: build.query<GetTestcaseResultsByRunApiResponse, GetTestcaseResultsByRunApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/testcase-result`,
          params: {
            crashes_only: queryArg.crashesOnly,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            phase: queryArg.phase,
            q: queryArg.q,
            result_filter: queryArg.resultFilter,
            sorted_by: queryArg.sortedBy,
            status_filters: queryArg.statusFilters,
            type_filters: queryArg.typeFilters
          }
        }),
        providesTags: ['testcase report']
      }),
      getTestcaseReportByRunAndChecksum: build.query<GetTestcaseReportByRunAndChecksumApiResponse, GetTestcaseReportByRunAndChecksumApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/testcase_report/${queryArg.sha256}`
        }),
        providesTags: ['testcase report']
      }),
      getDefectStats: build.query<GetDefectStatsApiResponse, GetDefectStatsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/defect-stat`,
          params: { project_slug: queryArg.projectSlug, target_slug: queryArg.targetSlug }
        }),
        providesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetDefectByTargetApiResponse = /** status 200 Retrieves a single defect that matches the given
    owner/project/target/defect_number.

    :param target: Target extracted from the path
    :param defect_number: Defect number extracted from the path
    :param branch_name: an optional filter used to select the defect state for the
                        given branch. If not specified, the Project default branch
                        is used.
    :param no_branch: an explicit boolean that filters defect state details to
                      runs without branch information. When this is true,
                      branch_name cannot be set.

    Returns:
        Defect -- The matching defect.
     */ Defect
export type GetDefectByTargetApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  branchName?: string | null
  noBranch?: boolean
}
export type PutTargetDefectApiResponse = /** status 200 Updates some attributes of a defect from the provided payload.
    owner/project/target/defect_number.

    :param target: Target extracted from the path
    :param defect_number: Defect number extracted from the path
    :param defect_update_args: Defect attributes that can be modified

    Returns:
        Defect -- The matching defect following update.
     */ Defect
export type PutTargetDefectApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  targetDefectPut: TargetDefectPut
}
export type GetDefectNavigationApiResponse = /** status 200 Retrieves the previous and next defect number for a defect based on a run.

    Returns:
        previous defect number -- The number of the previous defect if it exists.
        next defect number     -- The number of the next defect if it exists.
     */ DefectSchemaWithNavigation
export type GetDefectNavigationApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  runNumber?: number | null
}
export type GetDefectStatesApiResponse = /** status 200 Get a list of states for the defect.
    Returns:
        states -- list of states for the defect
        count -- total number of states
     */ DefectStates
export type GetDefectStatesApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetTestcaseReportsByDefectApiResponse = /** status 200 Returns list of test case reports for a given defect.

    Returns:
        list(Defect) -- list of defect objects in the specified target.
        count -- total number of defects
     */ DefectTestcaseReportList
export type GetTestcaseReportsByDefectApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  runNumber?: number | null
}
export type GetValgrindErrorsByDefectApiResponse = /** status 200 Get valgrind errors for a given defect.

    :param target: the target
    :param defect_number: the number of the defect
    :param offset: the offset of the results to retrieve, 0-indexed,
    :param per_page: the number of results to retrieve per page

    Returns
    valgrind_errors: List of valgrind errors
    count: Total number of valgrind errors
     */ ValgrindErrorList
export type GetValgrindErrorsByDefectApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetProjectDefectsApiResponse = /** status 200 Get defects for a project in a owner, optionally filtered by target.

    The target to filter on is determined by the target slug passed in the query
    parameter `target`.

    The run to filter on is determined by the run number passed in the query
    parameter `run`. A value of "latest" here will return defects for the most
    recent run based on the run's created_at time.

    Pagination defaults to 10 per page and starts on offset 0.

    :param project: the project object
    :param offset: the offset of the results to retrieve, 0-indexed
    :param per_page: how many results to retrieve per page
    :param target: an optional filter on the target
    :param run: an optional filter on the run number
    :param defect_type: an optional filter on the defect CWE number
    :param branch_name: an optional filter used to select the defect state for the
                        given branch. If not specified, the Project default branch
                        is used.
    :param no_branch: an explicit boolean that filters defect state details to
                      runs without branch information. When this is true,
                      branch_name cannot be set.

    :returns: A dict where the `defects` key contains a list of the desired defects
     */ DefectList
export type GetProjectDefectsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  branchName?: string | null
  defectType?: any | null
  lastSeenGte?: string
  lastSeenLt?: string
  noBranch?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  /** case-insensitive search across defect title, CWE #, and target */
  q?: string
  run?: any | null
  severity?: 'low' | 'medium' | 'high'
  sortedBy?: ('created_at' | '-created_at' | 'severity' | '-severity' | 'defect_state' | '-defect_state')[]
  status?: 'open' | 'closed'
  target?: string | null
}
export type GetDefectStatsByProjectApiResponse = /** status 200 Get stats for defects in a project in a owner, optionally filtered by target.

    The target to filter on is determined by the target slug passed in the query
    parameter `target`.

    The run to filter on is determined by the run number passed in the query
    parameter `run`. A value of "latest" here will return defects for the most
    recent run based on the run's created_at time.

    :param project: the project object
    :param target: an optional filter on the target
    :param run: an optional filter on the run number

    :returns: A dict with keys `count` integer value and `types`, a dict with
               keys of pretty-printed defect types and values of integer counts.
     */ DefectStats
export type GetDefectStatsByProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  run?: any | null
  target?: string | null
}
export type GetDefectJiraFieldsApiResponse = /** status 200 Returns the Jira fields for a defect

    Returns:
        Jira fields for a defect
     */ DefectJiraFields
export type GetDefectJiraFieldsApiArg = {
  defectNumber: number
  jiraIssueType: string
  jiraProjectKey: string
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetIssueRulesApiResponse = /** status 200 Returns the list of issue rules. This includes global rules. */ IssueRule[]
export type GetIssueRulesApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type GetIssueRuleApiResponse = /** status 200 Return a rule based on ID. This may return a global rule if there is a match. */ IssueRule
export type GetIssueRuleApiArg = {
  issueRuleId: string
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type GetDefectTrendApiResponse = /** status 200 Generates time-series for active and fixed defects by severity rating
    (high/medium/low).

    :param owner: The workspace to calculate trends for.
    :param window_start_timestamp_seconds: The start of the time-series.
    :param window_end_timestamp_seconds: The end of the time-series.
    :param window_step_seconds: The time in seconds between time-series points.
    :param project: Optionally, filter trends for a specific project under owner.
    :param target: Optionally, filter trends for a specific target under owner.
    :return: A time-series
     */ DefectTrend
export type GetDefectTrendApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  windowEndTimestampSeconds: number
  windowStartTimestampSeconds: number
  windowStepSeconds: number
  project?: string | null
  target?: string | null
}
export type GetRestApiIssuesApiResponse = /** status 200 Fetch all the REST API issues for a run */ RestApiIssueList
export type GetRestApiIssuesApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  defectNumbers?: number[]
  /** If set, Testcase Reports which have been suppressed by a user will be excluded in the result of the query. */
  excludeSuppressed?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetProjectRestApiIssuesApiResponse = /** status 200 Fetch all the REST API issues for a run */ RestApiIssueList
export type GetProjectRestApiIssuesApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  defectNumbers?: number[]
  /** If set, Testcase Reports which have been suppressed by a user will be excluded in the result of the query. */
  excludeSuppressed?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetRestApiIssueApiResponse = /** status 200 Fetch all the REST API issues for a run */ GetRestApiIssue
export type GetRestApiIssueApiArg = {
  defectNumber: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetTestcaseResultsByRunApiResponse = /** status 200 Returns a list of all testcase results.
    This list can be filtered to be only dynamic analysis or regression
     */ TestcaseResultList
export type GetTestcaseResultsByRunApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  crashesOnly?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  phase?: string
  q?: string
  resultFilter?: string
  sortedBy?: (
    | 'status'
    | '-status'
    | 'test_id'
    | '-test_id'
    | 'duration'
    | '-duration'
    | 'seen_at'
    | '-seen_at'
    | 'defect_count'
    | '-defect_count'
    | 'defect_count_high_severity'
    | '-defect_count_high_severity'
    | 'defect_count_medium_severity'
    | '-defect_count_medium_severity'
    | 'defect_count_low_severity'
    | '-defect_count_low_severity'
  )[]
  statusFilters?: ('passing' | 'failing')[]
  typeFilters?: (
    | 'new_test'
    | 'regression_test_fixed'
    | 'regression_test_regressed'
    | 'regression_test_still_failing'
    | 'regression_test_still_passing'
  )[]
}
export type GetTestcaseReportByRunAndChecksumApiResponse = /** status 200 Retrieves a single dynamic analysis test case report that matches
    the given owner/project/target/run_number/sha256.

    Returns:
        Report -- The matching test case report.
     */ TestcaseReport
export type GetTestcaseReportByRunAndChecksumApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The sha256 checksum of the underlying test case */
  sha256: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetDefectStatsApiResponse = /** status 200 Get stats for the referenced account */ DefectStats1
export type GetDefectStatsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  projectSlug?: string
  targetSlug?: string
}
export type Defect = {
  created_at?: string
  updated_at?: string
  defect_number?: number
  target_slug: string
  title?: string
  cwe_number?: number | null
  from_valgrind?: boolean
  type?: 'crash' | 'valgrind' | 'mapi' | 'zap'
  cwe_type?: string
  cwe_description?: string
  cwe_link?: string
  description?: string
  severity?: number
  severity_level?: 'low' | 'medium' | 'high'
  defect_class?: string
  defect_class_name?: string
  defect_class_short_description?: string
  defect_class_full_description?: string
  jira_issue_id?: string | null
  jira_issue_url?: string | null
  state?: 'open' | 'closed'
  branch?: string | null
  first_seen?: string
  last_seen?: string
  last_seen_run_id?: number
}
export type ApiError = {
  message?: string
}
export type TargetDefectPut = {
  branch?: string | null
  no_branch?: boolean
  state?: ('open' | 'closed' | null) | null
}
export type DefectSchemaWithNavigation = {
  created_at?: string
  updated_at?: string
  previous_defect_number?: number
  next_defect_number?: number
}
export type DefectState = {
  branch: string | null
  state: 'open' | 'closed'
  first_seen?: string
  last_seen?: string
}
export type DefectStates = {
  states: DefectState[]
  count: number
}
export type BaseTestcaseReport = {
  created_at?: string
  updated_at?: string
  testcase_report_id: number
  testcase_number: number
  run_number: number
  sha256: string
  testcase_source?: string
  repro_cmdline?: string
  output?: string
  crashed?: boolean
  timed_out?: boolean
  is_regression?: boolean
  backtrace?: string
  parsed_backtrace?: object[]
  registers?: string
  disassembly?: string
  att_disassembly?: string
  stack?: string
  maps?: string
  cwe_number?: number
  signal_number?: number
  faulting_address?: string
  crash_type?: string
  defect_hash?: string
  valgrind_completed?: boolean
  valgrind_failed?: boolean | null
  valgrind_failed_msg?: string
  valgrind_errors_truncated?: boolean
  valgrind_timed_out?: boolean
  valgrind_crashed?: boolean
  triage_ok?: boolean
  triage_errors?: string
  execution_time?: number | null
}
export type DefectTestcaseReportList = {
  testcase_reports?: BaseTestcaseReport[]
  count?: number
}
export type TestcaseReportDefect = {
  defect_number?: number
  title?: string
  cwe_number?: number
  severity?: number
  severity_level?: 'low' | 'medium' | 'high'
}
export type ValgrindError = {
  created_at?: string
  updated_at?: string
  valgrind_error_id?: number
  testcase_number?: number
  run_number?: number
  defect?: TestcaseReportDefect
  severity?: number
  sha256?: string
  cwe_number: number
  defect_hash: string
  backtrace: string
  error_type?:
    | 'InvalidFree'
    | 'MismatchedFree'
    | 'InvalidRead'
    | 'InvalidWrite'
    | 'InvalidJump'
    | 'Overlap'
    | 'InvalidMemPool'
    | 'UninitCondition'
    | 'UninitValue'
    | 'SyscallParam'
    | 'ClientCheck'
    | 'Leak_DefinitelyLost'
    | 'Leak_IndirectlyLost'
    | 'Leak_PossiblyLost'
    | 'Leak_StillReachable'
    | 'FishyValue'
    | 'CoreMemError'
  repro_cmdline?: string | null
  what?: string | null
  xwhat?: string | null
  auxwhat?: string | null
  xauxwhat?: string | null
}
export type ValgrindErrorList = {
  created_at?: string
  updated_at?: string
  valgrind_errors?: ValgrindError[]
  count?: number
}
export type DefectList = {
  defects: Defect[]
  count?: number
}
export type ProjectDefectBreakdownItem = {
  created_at?: string
  updated_at?: string
  cwe_number: number | null
  cwe_name: string
  cwe_short_name: string
  count: number
}
export type DefectStat = {
  count: number
  high: number
  medium: number
  low: number
  breakdown: ProjectDefectBreakdownItem[]
}
export type ProjectDefectBreakdownLegacy = {
  created_at?: string
  updated_at?: string
  cwe_number: number | null
  cwe_name: string
  count: number
}
export type DefectStats = {
  active: DefectStat
  count: number
  fixed: DefectStat
  total: DefectStat
  types: ProjectDefectBreakdownLegacy[]
}
export type JiraFieldOption = {
  created_at?: string
  updated_at?: string
  key: string
  value: string
}
export type JiraField = {
  created_at?: string
  updated_at?: string
  key: string
  name: string
  required: boolean
  type:
    | 'text'
    | 'number'
    | 'select'
    | 'search'
    | 'component'
    | 'sprint'
    | 'priority'
    | 'fixVersion'
    | 'epic link'
    | 'affectedVersion'
    | 'textarea'
    | 'parent'
  url?: string
  options: JiraFieldOption[]
}
export type JiraIssueTypeExpanded = {
  created_at?: string
  updated_at?: string
  name: string
  fields?: JiraField[]
}
export type JiraProjectSchemaExpanded = {
  created_at?: string
  updated_at?: string
  key: string
  avatar: string
  issue_types?: JiraIssueTypeExpanded[]
}
export type DefectJiraFields = {
  created_at?: string
  updated_at?: string
  summary: string
  description: string
  cloud_id: string
  cloud_url: string
  projects?: JiraProjectSchemaExpanded[]
}
export type Cwe = {
  created_at?: string
  updated_at?: string
  cwe_id?: string
  name?: string
  description?: string
  link?: string
  defect_class?: string | null
}
export type IssueRule = {
  created_at?: string
  updated_at?: string
  issue_rule_id: string
  external_id: string
  severity_score?: number
  tags?: string[] | null
  short_description?: string | null
  medium_description?: string | null
  full_description?: string | null
  hint?: string | null
  recommendation?: string | null
  references?: string | null
  examples?: string[] | null
  cwes?: Cwe[] | null
}
export type DefectTrendPoint = {
  timestamp?: string
  active?: number
  active_high?: number
  active_medium?: number
  active_low?: number
  fixed?: number
  fixed_high?: number
  fixed_medium?: number
  fixed_low?: number
}
export type DefectTrend = {
  trend?: DefectTrendPoint[]
}
export type HttpHeader = {
  key?: string
  value?: string
}
export type Frame = {
  path?: string
  line?: number
  function?: string
  snippet?: string
  scm_path?: string
}
export type StackTraceV1 = {
  language?: string
  message: string
  frames: Frame[]
  raw?: string
}
export type StackTrace = {
  V1: StackTraceV1
}
export type SecurityV1 = {
  id?: string
  scheme?: object
  params?: string[]
}
export type SecurityGroupV1 = {
  schemes?: SecurityV1[]
}
export type Security = {
  V1?: SecurityGroupV1[]
}
export type GetRestApiIssue = {
  created_at?: string
  defect_number?: number
  issue_rule_id: string
  summary: string
  request: string
  response: string
  body: string | null
  http_version?: string
  headers?: HttpHeader[]
  stacktrace?: StackTrace
  security?: Security
  auth_mutated?: boolean
  suppressed?: boolean
  suppressed_reason?: string
  is_from_replay?: boolean
  path?: string
  cwe_number?: number
  severity?: number
  severity_level?: 'low' | 'medium' | 'high'
  method?: string
  rendered_path?: string
  tags?: string[]
  endpoint_name?: string
  endpoint_external_id?: string
  target_slug?: string
  run_number?: number
  url?: string
}
export type RestApiIssueList = {
  issues?: GetRestApiIssue[]
}
export type TestcaseResult = {
  created_at?: string
  updated_at?: string
  testcase_report_id: number
  testcase_number: number
  run_number: number
  sha256: string
  testcase_source?: string
  repro_cmdline?: string
  output?: string
  crashed?: boolean
  timed_out?: boolean
  is_regression?: boolean
  backtrace?: string
  parsed_backtrace?: object[]
  registers?: string
  disassembly?: string
  att_disassembly?: string
  stack?: string
  maps?: string
  cwe_number?: number
  signal_number?: number
  faulting_address?: string
  crash_type?: string
  defect_hash?: string
  valgrind_completed?: boolean
  valgrind_failed?: boolean | null
  valgrind_failed_msg?: string
  valgrind_errors_truncated?: boolean
  valgrind_timed_out?: boolean
  valgrind_crashed?: boolean
  triage_ok?: boolean
  triage_errors?: string
  execution_time?: number | null
  n_valgrind_errors?: number
  defects?: TestcaseReportDefect[]
  previous_has_defects?: boolean
  n_defects?: number
}
export type TestcaseResultList = {
  testcase_reports?: TestcaseResult[]
  count?: number
  total_count?: number
  regression_count?: number
}
export type TestcaseReport = {
  created_at?: string
  updated_at?: string
  testcase_report_id: number
  testcase_number: number
  run_number: number
  sha256: string
  testcase_source?: string
  repro_cmdline?: string
  output?: string
  crashed?: boolean
  timed_out?: boolean
  is_regression?: boolean
  backtrace?: string
  parsed_backtrace?: object[]
  registers?: string
  disassembly?: string
  att_disassembly?: string
  stack?: string
  maps?: string
  cwe_number?: number
  signal_number?: number
  faulting_address?: string
  crash_type?: string
  defect_hash?: string
  valgrind_completed?: boolean
  valgrind_failed?: boolean | null
  valgrind_failed_msg?: string
  valgrind_errors_truncated?: boolean
  valgrind_timed_out?: boolean
  valgrind_crashed?: boolean
  triage_ok?: boolean
  triage_errors?: string
  execution_time?: number | null
  n_valgrind_errors?: number
  defects?: TestcaseReportDefect[]
  asan_output?: string
  valgrind_errors?: ValgrindError[]
  hexdump?: string
}
export type AccountDefectBreakdownItem = {
  created_at?: string
  updated_at?: string
  cwe_number: number | null
  cwe_name: string
  cwe_short_name: string
  count: number
}
export type DefectStat1 = {
  count: number
  high: number
  medium: number
  low: number
  breakdown: AccountDefectBreakdownItem[]
}
export type DefectStats1 = {
  active: DefectStat1
  fixed: DefectStat1
  total: DefectStat1
}
