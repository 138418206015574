import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Divider, Heading, Skeleton, Stack } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { Page } from '../../components/Page'
import { DataTab, DataTabs } from '../../components/DataTabs'
import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'

import { DsbomDefectsList } from '../dsbom-defects/DsbomDefectsList'
import { DsbomReportsList } from '../dsbom-reports/DsbomReportsList'

import { useGetMdsbomImageRepoQuery } from '../../redux/api/mdsbom'

import { DsbomImagePackages } from './DsbomImagePackages'
import { DsbomImageBigStats } from './DsbomImageBigStats'

interface Props {
  workspace: string
  imageRepoId: string
}

export function DsbomImagePage({ workspace, imageRepoId }: Props) {
  const { data: imageRepoData, isFetching } = useGetMdsbomImageRepoQuery({ workspace, imageRepoId })
  const { image_name: imageRepoName = '' } = imageRepoData || {}

  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <DsbomDefectsList workspace={workspace} imageRepoId={imageRepoId} />,
      route: `/${workspace}/-/dynamic-sbom/${imageRepoId}`
    },
    {
      label: 'Reports',
      content: <DsbomReportsList workspace={workspace} imageRepoId={imageRepoId} />,
      route: `/${workspace}/-/dynamic-sbom/${imageRepoId}/-/reports`
    },
    {
      label: 'Packages',
      content: <DsbomImagePackages workspace={workspace} imageRepoId={imageRepoId} />,
      route: `/${workspace}/-/dynamic-sbom/${imageRepoId}/-/packages`
    }
  ]
  if (isFetching) {
    return <Skeleton />
  }

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/-/dynamic-sbom`}>
              <BreadcrumbLink>Dynamic SBOM</BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={imageRepoName} />
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Stack spacing={4}>
        <Stack>
          <Heading>{imageRepoName}</Heading>
          <Divider />
        </Stack>
        <DsbomImageBigStats workspace={workspace} imageRepoId={imageRepoId} />
        <DataTabs isLazy variant="line" width="full" tabs={tabData} />
      </Stack>
    </Page>
  )
}
