import { Link } from 'wouter'
import { Center, Heading, Icon, Stack } from '@chakra-ui/react'

import { Footer } from '../../components/layout/Footer'
import MayhemColorLogo from '../../images/mayhem-m-color.svg?react'

export default function AuthLayout({ title, children }: { title: string; children: JSX.Element | JSX.Element[] }): JSX.Element {
  return (
    <Center height="full" flexDirection="column">
      <Stack maxWidth="xl" align="center">
        <Link to="/">
          <Icon as={MayhemColorLogo} boxSize={72} />
        </Link>

        <Heading size="sm" mb={4}>
          {title}
        </Heading>
        {children}
        <Footer />
      </Stack>
    </Center>
  )
}
