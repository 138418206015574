import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading, Stack } from '@chakra-ui/react'

import { getProjectDefectsPageTitle } from '../../titles'

import { useQuery } from '../../hooks'
import ProjectDefectsHeader from '../defect/ProjectDefectsHeader'
import { ProjectDefectsList } from '../defect/ProjectDefectsList'

import { useGetDefectStatsByProjectQuery } from '../../redux/api/defects'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspace: string
  project: string
}

export function ProjectDefectsPage(props: Props) {
  const { workspace, project: projectSlug } = props
  const query = useQuery()

  const targetFilter = query.get('target') || 'all'
  const runFilter = query.get('run') || 'all'
  const defectFilter = query.get('type') || 'all'

  useEffect(() => {
    document.title = getProjectDefectsPageTitle(workspace, projectSlug)
  }, [workspace, projectSlug, targetFilter, runFilter, defectFilter])

  const { isLoading: isFetchingDefectsStats, data: defectStats } = useGetDefectStatsByProjectQuery({
    owner: workspace,
    projectSlug,
    target: targetFilter === 'all' ? undefined : targetFilter,
    run: runFilter === 'all' ? undefined : runFilter
  })

  // currently "active" defects mean the defects from the latest run
  const defectSet = runFilter === 'latest' ? defectStats?.active : defectStats?.total
  const defectTypes = defectSet?.breakdown.map((entry) => {
    return { name: entry.cwe_short_name, count: entry.count, cweNumber: entry.cwe_number, fullName: entry.cwe_name }
  })

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Defects</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>Defects</Heading>
      <Stack spacing={8}>
        <ProjectDefectsHeader nDefects={defectSet?.count} defectTypes={defectTypes} isFetching={isFetchingDefectsStats} defectFilter={defectFilter} />
        <ProjectDefectsList defectTypes={defectTypes} workspace={workspace} projectSlug={projectSlug} />
      </Stack>
    </Page>
  )
}
