export function endpointDetailUrl(
  owner: string,
  projectSlug: string,
  targetSlug: string,
  runNumber: number,
  endpointMethod: string,
  endpointPath: string,
  endpointPreamble?: string
): string {
  return `/${owner}/${projectSlug}/${targetSlug}/${runNumber}/endpoint${endpointPreamble ?? ''}/${endpointMethod}/${encodeURIComponent(endpointPath)}`
}
