import { Box, Container, Drawer, DrawerContent, DrawerOverlay, Grid, useBreakpointValue, useColorModeValue, useDisclosure } from '@chakra-ui/react'

import { ReactNode, memo } from 'react'

import PageAccentBackgroundLight from '../../images/page-accent-bg.svg'
import PageAccentBackgroundDark from '../../images/page-accent-bg-darkmode.svg'

import { MobileNavbar } from './MobileNavbar'

const ShellInner = ({ navbar, sidebar, children, submenuOpen = false, showPageAccentBackground = false }: ShellProps) => {
  const { isOpen: isDrawerOpen, onToggle: onDrawerToggle, onClose: onDrawerClose } = useDisclosure()
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { fallback: 'lg' })
  const BackgroundAccentImage = useColorModeValue(PageAccentBackgroundLight, PageAccentBackgroundDark)

  const backgroundProps = showPageAccentBackground
    ? {
        style: { backgroundImage: `url("${BackgroundAccentImage}")` },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover'
      }
    : {
        background: 'pageBg'
      }

  const sidenavWidth = submenuOpen ? '17rem' : '12.5rem'

  return (
    <Grid
      as="section"
      height="100vh"
      bg="bg-canvas"
      templateColumns={{ base: '1fr', lg: `${sidenavWidth} 1fr` }}
      templateAreas={{
        base: `
          "mobile-navbar"
          "page"
        `,
        lg: `
          "sidebar page"
        `
      }}
      css={{
        '@media print': {
          // prevents a blank page at the start when printing from Firefox
          display: 'block'
        }
      }}
    >
      {isDesktop ? (
        <Box
          gridArea="sidebar"
          display="flex"
          flexDirection="column"
          height="100vh"
          overflowY="auto" // Scrollable sidebar
          bg="bg-sidebar"
          position="fixed"
        >
          {sidebar}
        </Box>
      ) : (
        <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose} isFullHeight preserveScrollBarGap>
          <DrawerOverlay />
          <DrawerContent maxWidth={sidenavWidth}>{sidebar}</DrawerContent>
        </Drawer>
      )}
      {!isDesktop && <MobileNavbar isOpen={isDrawerOpen} onToggle={onDrawerToggle} />}
      {navbar}
      <Container
        padding="0"
        flex="1"
        maxW={{ base: 'full', lg: 'unset' }}
        minW={0}
        gridArea="page"
        {...backgroundProps}
        css={{
          '@media print': {
            overflow: 'visible',
            breakInside: 'avoid'
          }
        }}
      >
        <Box width="full">{children}</Box>
      </Container>
    </Grid>
  )
}

export const Shell = memo(ShellInner)
export interface ShellProps {
  navbar?: JSX.Element
  sidebar: JSX.Element
  alert?: JSX.Element
  children?: ReactNode
  showPageAccentBackground?: boolean
  submenuOpen?: boolean
}
