import { useEffect, useRef } from 'react'
import { Container, ContainerProps, Flex, Heading, HStack } from '@chakra-ui/react'

import { useQuery } from '@/hooks'
import { NavbarSearch } from '@/features/workspace/NavbarSearch'
import { UserProfileWidget } from '@/features/workspace-sidebar/UserProfileWidget'

interface PageProps extends ContainerProps {
  header?: string | React.ReactNode
}

export function Page({ header, children, ...props }: PageProps): JSX.Element {
  const queryParams = useQuery()
  const jumpTo = queryParams.get('jumpTo')
  const scroll = queryParams.get('scroll')

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // jump to specified section (but make sure it's not interfering with the scroll behavior)
    if (jumpTo && !scroll) {
      const element = document.getElementById(jumpTo)
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'start' })
      }
    }
  }, [jumpTo, scroll])

  useEffect(() => {
    // jump to the specific section on initial page load (which can ignore the scroll behavior)
    if (jumpTo) {
      const element = document.getElementById(jumpTo)
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'start' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxW="full" paddingX={{ base: 4, lg: 8 }} overflowY="auto" {...props}>
      <Flex alignItems="center" wrap="wrap-reverse" marginTop={2} marginRight={-4} marginBottom={4}>
        {typeof header === 'string' ? <Heading>{header}</Heading> : header}
        <HStack ref={containerRef} justifyContent="flex-end" flexGrow={1}>
          <NavbarSearch />
          <UserProfileWidget containerRef={containerRef} />
        </HStack>
      </Flex>
      {children}
    </Container>
  )
}
