import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postProjectTeamMember: build.mutation<PostProjectTeamMemberApiResponse, PostProjectTeamMemberApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/team`,
          method: 'POST',
          body: queryArg.projectTeam
        }),
        invalidatesTags: ['internal']
      }),
      getProjectTeamMembers: build.query<GetProjectTeamMembersApiResponse, GetProjectTeamMembersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/team`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage, sorted_by: queryArg.sortedBy }
        }),
        providesTags: ['internal']
      }),
      putProjectTeamMember: build.mutation<PutProjectTeamMemberApiResponse, PutProjectTeamMemberApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/team/${queryArg.teamSlug}`,
          method: 'PUT',
          body: queryArg.projectMemberPermission
        }),
        invalidatesTags: ['internal']
      }),
      deleteProjectTeamMember: build.mutation<DeleteProjectTeamMemberApiResponse, DeleteProjectTeamMemberApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/team/${queryArg.teamSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      postTeam: build.mutation<PostTeamApiResponse, PostTeamApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/team`, method: 'POST', body: queryArg.newTeam }),
        invalidatesTags: ['internal']
      }),
      getTeamsByAccount: build.query<GetTeamsByAccountApiResponse, GetTeamsByAccountApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/team`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      }),
      deleteTeam: build.mutation<DeleteTeamApiResponse, DeleteTeamApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/team/${queryArg.teamSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      postTeamMember: build.mutation<PostTeamMemberApiResponse, PostTeamMemberApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/team/${queryArg.teamSlug}/user`,
          method: 'POST',
          body: queryArg.newTeamMember
        }),
        invalidatesTags: ['internal']
      }),
      getTeamMembers: build.query<GetTeamMembersApiResponse, GetTeamMembersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/team/${queryArg.teamSlug}/user`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['internal']
      }),
      deleteTeamMember: build.mutation<DeleteTeamMemberApiResponse, DeleteTeamMemberApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/team/${queryArg.teamSlug}/user/${queryArg.userSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      getTeamMember: build.query<GetTeamMemberApiResponse, GetTeamMemberApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/team/${queryArg.teamSlug}/user/${queryArg.userSlug}` }),
        providesTags: ['internal']
      }),
      getTeamsByUser: build.query<GetTeamsByUserApiResponse, GetTeamsByUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/team`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type PostProjectTeamMemberApiResponse = /** status 200 Add a user to the organization */ Ok
export type PostProjectTeamMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  projectTeam: ProjectTeam
}
export type GetProjectTeamMembersApiResponse = /** status 200 Return all project teams. */ ProjectTeams
export type GetProjectTeamMembersApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  sortedBy?: ('team' | '-team' | 'permission' | '-permission' | 'updated_at' | '-updated_at')[]
}
export type PutProjectTeamMemberApiResponse = /** status 200 Update ProjectTeamMember */ Ok
export type PutProjectTeamMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  teamSlug: string
  projectMemberPermission: ProjectMemberPermission
}
export type DeleteProjectTeamMemberApiResponse = /** status 200 Remove a member from a project */ Ok
export type DeleteProjectTeamMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  teamSlug: string
}
export type PostTeamApiResponse = /** status 200 Creates a single organization, and returns creation data. */ NewTeam
export type PostTeamApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  newTeam: NewTeam
}
export type GetTeamsByAccountApiResponse = /** status 200 Fetch all orgs from the database */ Teams
export type GetTeamsByAccountApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type DeleteTeamApiResponse = /** status 200 Deletes the team. The user needs to be authenticated and owner of the org. */ Ok
export type DeleteTeamApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  teamSlug: string
}
export type PostTeamMemberApiResponse = /** status 200 Add a user to a team */ Ok
export type PostTeamMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  teamSlug: string
  newTeamMember: NewTeamMember
}
export type GetTeamMembersApiResponse = /** status 200 Return all team members and the total count of team members. */ TeamMembers
export type GetTeamMembersApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  teamSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type DeleteTeamMemberApiResponse = /** status 200 Remove a member from an org */ Ok
export type DeleteTeamMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  teamSlug: string
  userSlug: string
}
export type GetTeamMemberApiResponse = /** status 200 Return team member. */ TeamMember
export type GetTeamMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  teamSlug: string
  userSlug: string
}
export type GetTeamsByUserApiResponse = /** status 200 Return the teams of the user */ Teams
export type GetTeamsByUserApiArg = {
  userSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type Ok = {
  result?: string
}
export type ApiError = {
  message?: string
}
export type ProjectTeam = {
  slug: string
  teamname?: string
  permission?: 'READ' | 'WRITE' | 'ADMIN'
  updated_at?: string
}
export type ProjectTeams = {
  created_at?: string
  updated_at?: string
  teams?: ProjectTeam[]
  count?: number
}
export type ProjectPermissionContext = {
  created_at?: string
  updated_at?: string
  org?: 'READ' | 'WRITE' | 'ADMIN'
  user?: 'READ' | 'WRITE' | 'ADMIN'
  team?: 'READ' | 'WRITE' | 'ADMIN'
}
export type ProjectMemberPermission = {
  created_at?: string
  updated_at?: string
  permission: 'READ' | 'WRITE' | 'ADMIN'
  context?: ProjectPermissionContext
}
export type NewTeam = {
  created_at?: string
  updated_at?: string
  teamname: string
}
export type Team = {
  created_at?: string
  updated_at?: string
  teamname: string
  slug: string
  orgname: string
  owner: string
}
export type Teams = {
  created_at?: string
  updated_at?: string
  teams?: Team[]
  count?: number
}
export type NewTeamMember = {
  created_at?: string
  updated_at?: string
  slug: string
}
export type TeamMember = {
  created_at?: string
  updated_at?: string
  user_id: number
  slug: string
  username: string
  first_name?: string | null
  last_name?: string | null
  active: boolean
}
export type TeamMembers = {
  created_at?: string
  updated_at?: string
  users?: TeamMember[]
  count?: number
}
