import { Box, HStack, Stack } from '@chakra-ui/react'

import { DefectsTagV2 } from './DefectsTagV2'

export interface DefectsBySeverityRowV2Props {
  high: number
  medium: number
  low: number
  showTotal?: boolean
}

export const DefectsBySeverityRowV2 = ({ high, medium, low, showTotal = true }: DefectsBySeverityRowV2Props) => {
  return (
    <Stack width="fit-content">
      <HStack spacing={2} width="fit-content">
        <DefectsTagV2 severity="High" count={high} />
        <DefectsTagV2 severity="Medium" count={medium} />
        <DefectsTagV2 severity="Low" count={low} />
      </HStack>
      {showTotal && (
        <HStack justifyContent="space-between" borderRadius="lg" background="subtleBg" paddingX={2} fontSize={12}>
          <Box textTransform="uppercase" fontSize={10}>
            Total
          </Box>
          <Box>{high + medium + low}</Box>
        </HStack>
      )}
    </Stack>
  )
}
